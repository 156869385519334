import '@unocss/reset/tailwind.css'
import '../styles/globals.scss'
import type { AppProps } from 'next/app'
import Footer from '../layouts/Footer'
import Head from 'next/head'
import useCurrentUser from '../hooks/useCurrentUser'

function App({ Component, pageProps }: AppProps) {
  const { currentUser, jwt, jwtError, likesReservation, mccReservation } =
    useCurrentUser()

  return (
    <>
      <Head>
        <title>SHElikes（シーライクス）無料体験レッスン受付中</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Head>
      <Component
        {...pageProps}
        currentUser={currentUser}
        jwtToken={jwt}
        jwtError={jwtError}
        likesReservation={likesReservation}
        mccReservation={mccReservation}
      />
      <Footer hideFooter={pageProps.hideFooter} />
    </>
  )
}

App.getInitialProps = async () => ({ pageProps: {} })

export default App
