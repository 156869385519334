export const fetcher = (url: string) =>
  fetch(url, { credentials: 'include' }).then(res => res.json())

export const fetcherAll = (...urls: [string]) =>
  Promise.all(urls.map((url: string) => fetcher(url)))

export const fetcherWithAuth = (url: string, jwtToken?: string) =>
  fetch(url, {
    credentials: jwtToken ? 'omit' : 'include',
    headers: {
      ...(jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {}),
      'Content-Type': 'application/json',
    },
  }).then(res => res.json())
