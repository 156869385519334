import useSWR from 'swr'
import { fetcher, fetcherWithAuth } from '../utils/fetcher'
import { CurrentUser } from '../types/currentUser'
import { Reservation } from '../types/reservation'

export default function useCurrentUser() {
  // JWT取得
  const { data: jwt, error: jwtError } = useSWR(
    `${process.env.NEXT_PUBLIC_API_URL}/api/v3/users/current/jwt`,
    fetcher
  )

  // JWTが正しく取得できたら currentUser を取得する
  // jwtのレスポンスで、errorがtrueの場合は無効なJWTとして扱う
  const isJwtReady = jwt && !jwtError && !jwt.error && jwt.body

  const { data: currentUser, error: userError } = useSWR<CurrentUser>(
    isJwtReady
      ? `${process.env.NEXT_PUBLIC_API_URL}/api/v1/users/current`
      : null,
    url => fetcherWithAuth(url, jwt.body)
  )

  // JWTが有効なら reservations を取得する
  const { data: reservationResult, error: _ } = useSWR<{ body: Reservation[] }>(
    isJwtReady
      ? `${process.env.NEXT_PUBLIC_API_URL}/api/commons/v1/users/current/event_reservations?services=likes`
      : null,
    url => fetcherWithAuth(url, jwt.body)
  )

  const likesReservation = fetchReservation(
    reservationResult?.body,
    'likes',
    'normal'
  )
  const mccReservation = fetchReservation(
    reservationResult?.body,
    'multicreator',
    'expert'
  )

  return {
    currentUser: currentUser,
    jwt: isJwtReady ? jwt.body : null,
    jwtError: jwt && jwt.error ? jwt.body : jwtError,
    userError,
    likesReservation,
    mccReservation,
  }
}

const fetchReservation = (
  reservations: Reservation[] | undefined,
  key: string,
  target: string
) => {
  return reservations
    ? reservations
        .filter(reservation => 'TrialLesson' == reservation.reservable_type)
        .filter(reservation =>
          reservation.reservable.membership_plan_group_keys.includes(key)
        )
        .filter(reservation => target == reservation.reservable.target)
        .at(-1)
    : null
}
